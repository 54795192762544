import React from "react"
import { useSpring, animated } from "react-spring"

const customConfig = { mass: 20, tension: 500, friction: 100 }

export default function FadeIn(props) {
  const animationStyle = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: customConfig,
  })

  return <animated.div style={animationStyle}>{props.children}</animated.div>
}
