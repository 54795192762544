import React from "react"
import Particles from "react-particles-js"

export default class ShapeParticles extends React.Component {
  render() {
    return (
      <Particles
        width="100%"
        height="100%"
        params={{
          particles: {
            line_linked: {
              enable: false,
            },
            number: {
              value: 40,
            },
            size: {
              value: 20,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 10,
                sync: false,
              },
            },
            shape: {
              type: "images",
              images: [
                {
                  src:
                    "https://s3.amazonaws.com/old-portfolio.mcmains.net/shape-circle.png",
                  height: 20,
                  width: 20,
                },
                {
                  src:
                    "https://s3.amazonaws.com/old-portfolio.mcmains.net/shape-plus.png",
                  height: 20,
                  width: 20,
                },
                {
                  src:
                    "https://s3.amazonaws.com/old-portfolio.mcmains.net/shape-square.png",
                  height: 20,
                  width: 20,
                },
                {
                  src:
                    "https://s3.amazonaws.com/old-portfolio.mcmains.net/shape-squiggle.png",
                  height: 20,
                  width: 20,
                },
                {
                  src:
                    "https://s3.amazonaws.com/old-portfolio.mcmains.net/shape-triangle.png",
                  height: 20,
                  width: 20,
                },
              ],
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
          },
        }}
      />
    )
  }
}
